//CONTENT
.content-dark {
    background-color: rgb(75, 75, 75);
    // background-color: rgb(126, 126, 126);
    // background-color: rgb(57, 76, 126);
}

.content-light {
    // background-color: rgb(247, 247, 247);
    background-color: rgb(255, 255, 255);
}

//COMPONENT: SimpleCard
.simple-card-light {
    // background-color: rgb(255, 255, 255);
    border-color: #e6e6e6;
    border-radius: 6px;

    #title {
        color: #6e6e6e;
    }

    #footerText {
        color: rgb(71, 71, 71);
    }
}

.simple-card-background-light {
    background-color: rgb(225, 242, 255);
}

.simple-card-dark {
    background-color: rgb(66, 66, 66);
    border-color: #696969;
    border-radius: 6px;

    #mainNumber {
        color: rgb(120, 227, 253);
    }

    #title {
        color: rgb(226, 226, 226);
    }

    #footerText {
        color: rgb(202, 202, 202);
    }
}

.simple-card-background-dark {
    background-color: rgb(0, 68, 107);
}

.chart-card-light {
    background-color: rgb(255, 255, 255);
    border-color: #e6e6e6;
    border-radius: 6px;

    #title {
        color: #6e6e6e;
    }
}

.chart-card-dark {
    background-color: rgb(66, 66, 66);
    border-color: #696969;
    border-radius: 6px;

    #mainNumber {
        color: rgb(120, 227, 253);
    }

    #title {
        color: rgb(226, 226, 226);
    }
}