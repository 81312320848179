.card-wrapper {
    font-family: 'Arial', sans-serif;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    margin: 0;
    border-width: 1px;
  }

.dashboard-card {
    background-color: transparent;
    text-align: center;
    width: 100%;
}

.title {
    font-size: larger;
    // font-weight: bold;
    // text-transform: uppercase;
    margin-bottom: 15px;
}

.revenue {
    font-size: 28px;
    // font-weight: bold;
    color: #3498db;
    margin-bottom: 5px;
}

.currency-symbol {
  font-size: 18px;
}

.percentage {
    font-size: 14px;
}

.bottom-text {
    font-size: 12px;
    color: #777;
}

.positive {
  color: #2ecc71;
}

.negative {
  color: red;
}

