
.page-header {
    box-shadow: none !important;
    background-color: transparent !important;
}

// .page-header span {
//     font-size: 40px !important;
// }

.page-header .page-header-title {
    font-size: 20px;
    color: #03a9f4;
    background-color: transparent !important;
}

// .e-appbar {
//     font-size: 40px !important;
// }

// .calendar-button {
//     width: 10px;
// }