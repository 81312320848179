
.login-form-wrapper {
    max-width: 500px;
    max-height: 550px;
    width: 100%;
    height: 100%;
  }

.login-form-header {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-text {
    text-align: center;
    vertical-align: middle;
    height: 30%;
    font-weight: lighter;
    font-size: 28px;
}

.login-form {
    width: 96%;
    height: 78%;
    margin-left: 2%;
    margin-right: 2%;
  }

