.control-section {
    width: 100%;
    height: 96%;
    display: flex;
    flex-direction: row;
    margin-top: -10px;
}

.wrapper-layout {
    flex: 1; 
  }

 .wrapper-wrapper {
    background: transparent;
    // height: 100%;
    width: 100%;
}

.wrapper-header {
    background: transparent;
    box-shadow: none;
    height: auto;
    width: auto;
}

.wrapper-container {
    background: transparent;
    height: 100%;
    width: 100%;
}

.wrapper-header .e-appbar {
    background: transparent;
}