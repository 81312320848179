.card-wrapper {
    font-family: 'Arial', sans-serif;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    margin: 0;
    border-width: 1px;
  }

.dashboard-card {
    background-color: transparent;
    text-align: center;
    width: 100%;
    margin: 10px;
}

