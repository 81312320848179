.control-section {
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: row;
    margin-top: -10px;
}

.dashboard-layout {
    flex: 1; 
  }

 .dashboard-wrapper {
    background: transparent;
    height: 100%;
    width: 100%;
}

.dashboard-header {
    background: transparent;
    box-shadow: none;
    height: auto;
    width: auto;
}

.dashboard-container {
    background: transparent;
    height: 100%;
    width: 100%;
}

.dashboard-header .e-appbar {
    background: transparent;
}
